export default {
  admin: [
    {
      title: 'Managers',
      route: 'managers',
      icon: 'UsersIcon',
    },
    {
      title: 'Logs',
      route: 'logs',
      icon: 'TerminalIcon',
    },
    {
      title: 'Contents',
      route: 'content',
      icon: 'FileIcon',
      children: [
        // {
        //   title: 'About',
        //   route: 'about-content',
        //   icon: 'StopCircleIcon',
        // },
        {
          title: 'Contact',
          route: 'contact-content',
          icon: 'StopCircleIcon',
        },
        {
          title: 'Privacy Policy',
          route: 'privacy-content',
          icon: 'StopCircleIcon',
        },
        {
          title: 'Terms & Conditions',
          route: 'terms-content',
          icon: 'StopCircleIcon',
        },
        {
          title: 'FAQs',
          route: 'faq-content',
          icon: 'StopCircleIcon',
        },
        // {
        //   title: 'Reviews',
        //   route: 'reviews',
        //   icon: 'StopCircleIcon',
        // },
        {
          title: 'Footer',
          route: 'footer-content',
          icon: 'StopCircleIcon',
        },
      ],
    },
    {
      title: 'Data Center',
      route: 'data-center',
      icon: 'DatabaseIcon',
    },
  ],
  manager: [
    {
      title: 'Statistics',
      route: 'statistics',
      icon: 'BarChartIcon',
    },
    {
      title: 'Business Statistics',
      route: 'vendor-statistics',
      icon: 'BarChartIcon',
    },
    {
      title: 'Member Statistics',
      route: 'member-statistics',
      icon: 'BarChartIcon',
    },
    {
      title: 'Suspended Business',
      route: 'suspended-business',
      icon: 'AlertTriangleIcon',
    },
    {
      title: 'Reported Reviews',
      route: 'reported-reviews',
      icon: 'AlertTriangleIcon',
    },
    {
      title: 'Bad Reviews',
      route: 'bad-reviews',
      icon: 'AlertTriangleIcon',
    },
    {
      title: 'Members',
      route: 'members',
      icon: 'UsersIcon',
    },

    {
      title: 'Businesses',
      route: 'business-profile',
      icon: 'BriefcaseIcon',
      children: [

        {
          title: 'Profile',
          route: 'business-profile',
          icon: 'StopCircleIcon',
        },
        {
          title: 'Reviews',
          route: 'reviews',
          icon: 'StopCircleIcon',
        },
        {
          title: 'Transactions',
          route: 'transaction',
          icon: 'StopCircleIcon',
        },
      ],
    },
    {
      title: '',
      route: '',
      icon: 'BarsChartIcon',
    },
    {
      title: 'Staff',
      route: 'staffs',
      icon: 'UsersIcon',
    },
    {
      title: 'Settings',
      route: 'settings',
      icon: 'SettingsIcon',
      children: [
        {
          title: 'Categories',
          route: 'categories',
          icon: 'StopCircleIcon',
        },
        {
          title: 'Services',
          route: 'services',
          icon: 'StopCircleIcon',
        },
        // {
        //   title: 'Sub Categories',
        //   route: 'sub-categories',
        //   icon: 'StopCircleIcon',
        // },
        // {
        //   title: 'Tags',
        //   route: 'tags',
        //   icon: 'StopCircleIcon',
        // },
        {
          title: 'States',
          route: 'states',
          icon: 'StopCircleIcon',
        },
        {
          title: 'Counties',
          route: 'counties',
          icon: 'StopCircleIcon',
        },
      ],
    },
  ],
  staff: [
    {
      title: 'Members',
      route: 'members',
      icon: 'UsersIcon',
    },

    {
      title: 'Businesses',
      route: 'business-profile',
      icon: 'BriefcaseIcon',
      children: [

        {
          title: 'Profile',
          route: 'business-profile',
          icon: 'StopCircleIcon',
        },
        {
          title: 'Reviews',
          route: 'reviews',
          icon: 'StopCircleIcon',
        },
        {
          title: 'Transactions',
          route: 'transaction',
          icon: 'StopCircleIcon',
        },
      ],
    },
    {
      title: '',
      route: '',
      icon: 'BarsChartIcon',
    },
    {
      title: 'Settings',
      route: 'settings',
      icon: 'SettingsIcon',
      children: [
        {
          title: 'Categories',
          route: 'categories',
          icon: 'StopCircleIcon',
        },
        // {
        //   title: 'Sub Categories',
        //   route: 'sub-categories',
        //   icon: 'StopCircleIcon',
        // },
        // {
        //   title: 'Tags',
        //   route: 'tags',
        //   icon: 'StopCircleIcon',
        // },
        {
          title: 'States',
          route: 'states',
          icon: 'StopCircleIcon',
        },
        {
          title: 'Counties',
          route: 'counties',
          icon: 'StopCircleIcon',
        },
        {
          title: 'Services',
          route: 'services',
          icon: 'StopCircleIcon',
        },
      ],
    },
  ],
  vendor: [
    {
      title: 'Business',
      route: 'vendor-business-profile',
      icon: 'BriefcaseIcon',
      children: [
        {
          title: 'Profile',
          route: 'vendor-business-profile',
          icon: 'StopCircleIcon',
        },
      ],
    },
    // {
    //   title: 'Contacts',
    //   route: 'contacts',
    //   icon: 'BookIcon',
    // },

    // {
    //   title: 'Recomendations',
    //   route: 'vendor-recomendations',
    //   icon: 'CornerUpRightIcon',
    // },
    // {
    //   title: 'Reviews',
    //   route: 'reviews',
    //   icon: 'MessageSquareIcon',
    // },
  ],
  member: [
    {
      title: 'Contacts',
      route: 'contacts',
      icon: 'BookIcon',
    },

    {
      title: 'Recomendations',
      route: 'recomendations',
      icon: 'CornerUpRightIcon',
    },
    {
      title: 'Reviews',
      route: 'reviews',
      icon: 'MessageSquareIcon',
    },
  ],
}
