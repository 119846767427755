<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <v-idle
      :loop="false"
      :reminders="[10]"
      :wait="5"
      :duration="600"
      style="visibility: hidden;"
      @idle="onidle"
      @remind="onremind"
    />
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user && user.first_name }} {{ user && user.last_name }}
            </p>
            <span class="user-status">{{ user && user.user_type }}</span>
          </div>
          <b-avatar
            v-if="user && user.avatar === null || user && user.avatar === ''"
            size="40"
            :text="getUserText(user.first_name, user.last_name)"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
          <b-avatar
            v-else
            size="40"
            variant="light-primary"
            badge
            :src="getImage(user && user.avatar)"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push({name: 'profile'}).catch(() => { })"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        user_type: '',
        avatar: '',
      },
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.profile
    },
  },
  watch: {
    '$store.state.user.profile': {
      handler() {
        this.user = this.userData
      },
      deep: true,
    },
  },
  mounted() {
    this.loadUser()
    this.getState()
    this.getAllCounties()
    this.getCategory()
    this.getAllSubCategories()
  },
  methods: {
    onidle() {
      this.logout()
    },
    onremind(time) {
      // alert seconds remaining to 00:00
      this.idleWarning(time)
    },
    idleWarning(time) {
      this.$swal({
        title: 'Warning!',
        text: `You have left this app idle for 10mins, left with ${time} seconds to logout the app.`,
        icon: 'warning',
        timer: 6000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    loadUser() {
      this.$store.dispatch('user/setProfile')
    },
    getState() {
      return this.$store.dispatch('state/getStates')
    },
    getAllCounties() {
      return this.$store.dispatch('county/getAllCounties')
    },
    getCategory() {
      return this.$store.dispatch('category/getCategories')
    },
    getAllSubCategories() {
      return this.$store.dispatch('subCategory/getAllSubCategories')
    },
    logout() {
      this.$store.dispatch('businessProfile/emptyListParam')
      this.$store.dispatch('businessProfile/emptyDefaultList')
      this.$store.dispatch('businessProfile/updateMessage', 'Please select state')
      this.$router.push({ name: 'login' }).catch(() => {})
      localStorage.clear()
    },
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    getUserText(firstName, lastName) {
      const firstChar = firstName.charAt(0)
      const lastChar = lastName.charAt(0)
      return firstChar + lastChar
    },
  },
}
</script>
